import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        navishow: true,
        icon: 'mdi-view-dashboard-outline',
        class:'',
        component: Home
    },
    {
        path: '/courses',
        name: 'Kurser',
        navishow: true,
        icon: 'mdi-school-outline',
        component: () => import('../views/Courses.vue')
    },
    {
        path: '/course/:id',
        name: 'Lektion',
        navishow: false,
        icon: 'mdi-view-dashboard-variant',
        component: () => import('../views/Course.vue')
    },
    {
        path: '/profil/:id',
        name: 'Profil',
        navishow: false,
        icon: 'mdi-view-dashboard-variant',
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/overblik',
        name: 'Overblik',
        navishow: true,
        icon: 'mdi-eye-outline',
        component: () => import('../views/Overview.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
