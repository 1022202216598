<template>
    <v-snackbar
        v-model="snackBar"
        bottom
        :timeout="timeout"
        :class="snackClass"
    >
        <span v-html="snackMsg"></span>
    </v-snackbar>
</template>

<script>
    export default {
        name: "snackBar",
        data() {
            return {
                snackBar: this.$store.state.snackBar.snackBar,
                snackMsg: this.$store.state.snackBar.snackMsg,
                snackLoad: this.$store.state.snackBar.snackLoad,
                timeout: this.$store.state.snackBar.timeout,
                snackClass: this.$store.state.snackBar.snackClass
            }
        },
        watch: {
            '$store.state.snackBar': {
                handler() {
                    this.snackBar = this.$store.state.snackBar.snackBar
                    this.snackMsg = this.$store.state.snackBar.snackMsg
                    this.snackLoad = this.$store.state.snackBar.snackLoad
                    this.snackClass = this.$store.state.snackBar.snackClass
                },
                deep: true
            },
        },
        computed: {}
    }
</script>
<style>
    .snackModule > .v-snack__wrapper {
        margin-bottom: 10px;
    }

    .snackModule > .v-snack__wrapper{
        background-color: #323232 !important;
        border-radius: 4px;
        overflow: hidden;
    }

    .snackModule.snackAlert > .v-snack__wrapper {
        background-color: #ff5252 !important;
        border-radius: 4px;
        color: #fff;
        overflow: hidden;
    }


</style>