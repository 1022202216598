<template>
    <v-navigation-drawer
            app
            floating
            v-model="$root.drawer"
            elevation="0"
    >
        <navigation-profile/>
        <v-list class="pa-0">
            <div
                    v-for="route in $router.options.routes"
                    :key="route.name"
            >
                <v-list-item
                        :to="route.path"
                        link
                        v-if="route.navishow"
                        :class="route.class"
                >
                    <v-list-item-avatar class="mt-1 mb-1">
                        <v-icon color="white" size="20">{{route.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <span class="font-weight-light white--text">{{route.name}}</span>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>

        <template v-slot:append>
            <v-list dense class="pa-0 "  style="background-color: #303c54">
                <v-list-item class="border-top-light" @click="$store.dispatch('auth/logout')" exact link>
                    <v-list-item-avatar class="mt-1 mb-1">
                        <v-icon color="white">mdi-logout</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import NavigationProfile from "./Navigation-profile";

    export default {
        name: "navigation",
        components: {NavigationProfile},
        data() {
            return {
                routes: [
                    {path: '/', icon: 'mdi-gauge-full', name: 'Dashboard'},
                    {path: '/courses', icon: 'mdi-school', name: 'Kurser'},
                    {path: '/overblik', icon: 'mdi-eye', name: 'Overblik'},
                ]
            }
        },
        mounted() {
        }


    }
</script>

<style scoped>
    .theme--light.v-navigation-drawer {
        background: #3c4b64;
    }
    .v-list-item__title, .v-list-item__subtitle {
        color: #fff;
    }
    .border-bottom-light{
        border-bottom:1px solid rgba(255,255,255,0.15) !important;
    }
    .border-top-light{
        border-top:1px solid rgba(255,255,255,0.15) !important;
    }
</style>