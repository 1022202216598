import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import GAuth from 'vue-google-oauth2'

Vue.config.productionTip = false
Vue.use(GAuth, {
    clientId: '944513942155-i52votus4afod43abgqe921us4vlladi.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
})

new Vue({
    router,
    store,
    vuetify,
    data() {
        return {
            drawer: true,
        }
    },
    render: h => h(App)
}).$mount('#app')
