<template>
    <v-card elevation="0">
        <v-toolbar dense elevation="0">
            <span>Seneste gennemførte kurser</span>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list-item-group>
            <v-skeleton-loader
                    type="list-item-avatar-two-line"
                    :loading="true"
                    v-if="loading"
                    v-for="(i,k) in 7"
                    :key="i"
            ></v-skeleton-loader>
            <v-list-item
                    v-for="course in courses"
                    :key="course.id"
                    :to="{name:'Lektion',params:{id:course.course}}"
                    dense
                    link
            >
                <v-list-item-avatar>
                    <v-icon color="green">mdi-license</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{course.name}}</v-list-item-title>
                    <v-list-item-subtitle>Af
                        <router-link :to="{name:'Profil',params:{id:course.userId}}">{{course.userName}}
                        </router-link>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-card>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "latestCompletedCourses",
        data() {
            return {
                courses: [],
                loading: true,

            }
        },
        methods: {
            getCompletedCourses() {
                api.get('/course/completed/latest')
                    .then(response => {
                        this.courses = response.data
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.getCompletedCourses()
        }
    }
</script>

<style scoped>

</style>