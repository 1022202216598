<template>
    <v-container>
        <v-row>
            <v-col class="col-md-4 col-xs-12 col-sm-6 ">
                <v-card elevation="0" class="mb-5">
                    <v-toolbar dense dark color="primary" elevation="0">
                        <span>KK Academy</span>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text class="text--primary">
                        <p>Velkommen til KK Academy hjemmesiden.</p>
                        <p>Herunder vil i kunne finde links til kurser, registrere tidsforbrug, se jeres fremgang
                            mv.</p>
                        <p>Tag et kig under jeres
                            <router-link :to="{name:'Profil',params:{id:$store.state.auth.user.id}}">profil
                            </router-link>
                            for at se et overblik
                        </p>
                        <v-subheader class="font-weight-bold">
                            Opdateringer
                        </v-subheader>
                        <ul>
                            <li>Tidsregistrering er nu mulig</li>
                            <li>Vurdering af kurser er nu mulig</li>
                            <li>Det er nu muligt at tilføje kurser</li>
                            <li>
                                <p class="font-weight-black">Der er nu en
                                    <v-btn x-small elevation="0" color="primary">Tilføj</v-btn>
                                    knap under tidsregistrering, til dem der ikke kunnde finde ud af at trykke [enter]
                                </p>
                            </li>
                        </ul>

                        <p class="red--text">HUSK: At registrere tidsforbrug</p>
                    </v-card-text>
                </v-card>
                <latest-ratings/>
            </v-col>
            <v-col class="col-md-4 col-xs-12 col-sm-6 ">
                <latest-completed-courses/>
            </v-col>
            <v-col class="col-md-4 col-xs-12 col-sm-6 ">
                <latest-time-notes/>
            </v-col>
            <v-col class="col-md-4 col-xs-12 col-sm-6 ">
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // @ is an alias to /src

    import api from "../services/api";
    import LatestCompletedCourses from "../components/latestCompletedCourses";
    import LatestTimeNotes from "../components/latestTimeNotes";
    import LatestRatings from "../components/latestRatings";

    export default {
        name: 'Home',
        components: {LatestRatings, LatestTimeNotes, LatestCompletedCourses},
        data() {
            return {}
        },
        methods: {},
        mounted() {
            api.get('user', {params: {id: this.$store.state.auth.user.id}})
        }
    }
</script>
