<template>
    <v-card elevation="0">
        <v-toolbar dense elevation="0" >
            <span>Seneste noteringer</span>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list-item-group>
            <v-skeleton-loader
                type="list-item-avatar-two-line"
                :loading="true"
                v-if="loading"
                v-for="(i,k) in 7"
                :key="i"
            ></v-skeleton-loader>
            <v-list-item
                    v-for="note in timeNotes"
                    :key="note.id"
                    :to="{name:'Lektion',params:{id:note.course}}"
                    dense
                    link
            >
                <v-list-item-avatar>
                    <v-icon color="green">mdi-clock-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{note.note}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <router-link :to="{name:'Profil',params:{id:note.userId}}">{{note.userName}}</router-link>
                        @
                        {{note.name}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-card>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "latestTimeNotes",
        data() {
            return {
                timeNotes: [],
                loading: true,
            }
        },
        methods: {
            getLatestNotes() {
                api.get('/course/time/latest')
                    .then(response => {
                        this.timeNotes = response.data
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.getLatestNotes()
        }
    }
</script>

<style scoped>

</style>