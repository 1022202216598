import api from './api.js' // <-- api.js (axios)

class AuthService {

    async login() {
        let userData
        await window.gapi.auth2.getAuthInstance() // <-- Browser gapi auth
            .signIn()
            .then(user => {
                return api.post('auth/login',
                    {
                        gid: user.getId()
                    })
                    .then(response => {
                        return response.data
                    })
            })
            .then(user => {
                userData = user.data
            })
        return userData
    }

    async authorize(user) {
        api.post('auth/authorize', user)
            .then(r => {
                console.log(r) // yay / nay
            })
    }

    logout() {
        localStorage.removeItem('user')
    }
}

export default new AuthService()