import AuthService from '../services/AuthService'
import api from "../services/api";

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
    ? {status: {loggedIn: true, authorized: true}, user: user}
    : {status: {loggedIn: false, authorized: false}, user: {usergroup: 0, email: ''}}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        loginAuthorized(state, user) {
            state.status.loggedIn = true
            state.status.authorized = true
            localStorage.setItem('user', JSON.stringify(user))
            state.user = user
        },
        loginUnauthorized(state, user) {
            state.status.loggedIn = true
            state.status.authorized = false
            state.user.email = user.email
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.status.authorized = false
            state.user = {usergroup: 0, email: ''}
        },
        logout(state) {
            state.status.loggedIn = false
            state.status.authorized = false
            state.user = {usergroup: 0, email: ''}
        },
        validToken(state, response) {
        }
    },
    actions: {
        login({commit}) {
            return AuthService
                .login()
                .then(user => {
                    if (user.accessToken) {
                        commit('loginAuthorized', user)
                    } else {
                        commit('loginUnauthorized', user)
                    }
                    return Promise.resolve(user)
                })
                .catch(error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },
        logout({commit}) {
            if (this.state.auth.status.loggedIn) {
                this.dispatch('snackBar/snackMsg', 'Logger ud')
                setTimeout(function () {
                    AuthService.logout()
                    commit('logout')
                }, 1250)
            }
        },
        validateToken({commit}) {
            api.get('/')
                .then(response => {
                    commit('validToken', response.data)
                })
                .catch(response => {
                    if (response.status === 400) {
                        AuthService.logout()
                        commit('logout')
                    }
                })
        },
        sessionExpired({commit}) {
            AuthService.logout()
            commit('logout')
            this.dispatch('snackBar/snackAlert', 'Session udløbet')
        }
    },
    getters: {
        getEmail() {
            return this.state.user.email
        }
    }
}