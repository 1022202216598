<template>
    <v-card
            v-if="!$store.state.auth.status.loggedIn || !$store.state.auth.status.authorized"
            class="d-flex align-content-space-around flex-column mx-auto pa-5 mt-12"
    >

        <v-btn
                @click="$store.dispatch('auth/login')"
                :disabled="!isInit"
                text
        >Log ind
        </v-btn>
        <v-chip
                class="mt-5"
                v-if="!$store.state.auth.status.authorized && $store.state.auth.status.loggedIn"
                color="error"
        >Ikke godkendt konto: {{$store.state.auth.user.email}}
        </v-chip>
    </v-card>
</template>
<script>
    import api from "../services/api";

    export default {
        name: "Login",
        data() {
            return {
                isInit: false,
                isSignIn: false,
                text: false
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn
            },
            authorized() {
                return this.$root.authorized;
            }
        },
        methods: {},
        mounted() {
            let that = this;
            let checkGauthLoad = setInterval(function () {
                that.isInit = that.$gAuth.isInit
                that.isSignIn = that.$gAuth.isAuthorized;
                if (that.isInit) clearInterval(checkGauthLoad);
            }, 200)
        }
    }
</script>