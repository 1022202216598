import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '../store/AuthModule'
import snackBar from "../store/SnackModule"

export default new Vuex.Store({
    modules: {
        auth,
        snackBar,
    }
})
