<template>
    <v-app class="bluegrey" v-if="$store.state.auth.status.authorized">
        <div>
            <navigation/>
            <appBar/>
            <v-main>
                <v-container fluid>
                    <router-view></router-view>
                </v-container>
            </v-main>
        </div>
        <SnackBar/>
    </v-app>
    <v-app v-else>
        <login/>
    </v-app>
</template>
<script>

    import appBar from "./components/App-bar.vue";
    import Login from "./components/Login";
    import Navigation from "./components/Navigation/Navigation";
    import SnackBar from "./components/snackBar";

    export default {
        components: {
            SnackBar,
            Login,
            Navigation,
            appBar,
        },
        data() {
            return {}
        },
        mounted() {
            console.log(this.$store.state)
        },
        watch: {
            $route(to, from) {
                //this.$store.dispatch('pageState/pageStatus', {code: 200, message: 'ok'})
            }
        }
    }
</script>
<style>
    #app {
        background-color:#eeeff4;
    }
</style>
