import axios from 'axios'
import store from '../store'

let api = axios.create({
    baseURL: 'https://api.kompetencekanalen.dk/',
    header: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
})
api.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (user) {
            config.headers.common["Authorization"] = 'Bearer ' + user.accessToken
        }
        return config
    },
    error => {
        console.log('error here')
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        if (error.response.status) {
            switch (true) {
                case error.response.status === 400:
                    this.dispatch('snackBar/snackAlert', 'ikke logget ind')
                    break;
                case error.response.status === 401:
                    this.dispatch('snackBar/snackAlert', 'ikke logget ind')
                    break;
                case error.response.status === 403:
                    this.dispatch('snackBar/snackAlert', 'Du har ikke rettigheder til denne handling')
                    break;
                case error.response.status === 404:
                    store.state.pageState.status = 404
                    break;
                case error.response.status === 498:
                    store.dispatch('auth/sessionExpired')
                    break;
                case error.response.status === 502:
                    console.log('Bad gateway')
                    break;
            }
            return Promise.reject(error.response)
        }
    }
)

export default api