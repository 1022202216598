<template>
    <v-list  class=" border-bottom-light pa-0" style="background-color: #303c54">
        <v-list-item
            :to="{name:'Profil',params:{id:$store.state.auth.user.id}}"
            exact
            link

        >
            <v-list-item-avatar
                size="28"
                class="ml-2 mr-5 mt-5 mb-4"
            >
                <v-img :src="$store.state.auth.user.gpic"/>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="white--text font-weight-light">
                    {{ $store.state.auth.user.name }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        name: "navigationProfile",
        data() {
            return {
                background: '/img/profile_background_1.jpg',
                profileRoute: {},
            }
        },
        computed: {},

        beforeCreate() {
            this.profileRoute = {name: 'Profil', params: {id: this.$store.state.auth.user.id}}
        }
    }
</script>

<style scoped>
    .border-bottom-light {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
    }

</style>