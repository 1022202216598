<template>
    <v-card elevation="0">
        <v-toolbar dense elevation="0">
            <span>Seneste anmeldelser</span>
        </v-toolbar>
        <v-divider/>
        <v-list-item-group>
            <v-skeleton-loader
                type="list-item-avatar-two-line"
                :loading="true"
                v-if="loading"
                v-for="(i) in 7"
                :key="i"
            ></v-skeleton-loader>
            <v-list-item
                v-for="rating in ratings"
                :key="rating.id"
                :to="{name:'Lektion',params:{id:rating.course}}"
                dense
                link
            >
                <v-list-item-content>
                    <v-list-item-title>
                        {{rating.courseName}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <router-link :to="{name:'Profil',params:{id:rating.userId}}">{{rating.name}}</router-link>
                        Af
                        <router-link :to="{name:'Profil',params:{id:rating.userId}}">{{rating.userName}}
                        </router-link>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon>
                        {{rating.rating}}
                        <v-icon color="yellow darken-2">mdi-star</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list-item-group>
    </v-card>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "latestRatings",
        data() {
            return {
                ratings: [],
                loading: true,
            }
        },
        methods: {
            getLatestRatings() {
                this.loading = true
                api.get('/course/ratings/latest')
                    .then(response => {
                        this.loading = false
                        this.ratings = response.data
                    })
            }
        },
        mounted() {
            this.getLatestRatings()
        }
    }
</script>

<style scoped>

</style>