export default {
    namespaced: true,
    state: {
        snackBar: false,
        snackMsg: 'Default message',
        snackLoad: true,
        snackClass: '',
        timeout: 4000,
    },
    mutations: {
        display(state) {
            state.snackBar = true
        },
        hide(state) {
            state.snackBar = false
        },
        setMsg(state, snackMsg) {
            state.snackMsg = snackMsg
        },
        setClass(state, snackClass) {
            state.snackClass = snackClass
        },
    },
    actions: {
        snackMsg({commit}, snackMsg) {
            this.state.snackBar.snackBar && commit('hide')
            commit('setClass', 'snackModule')
            commit('setMsg', snackMsg)
            commit('display')

            setTimeout(function () {
                commit('hide')
            }, this.state.snackBar.timeout)
        },
        snackAlert({commit}, snackMsg) {
            this.state.snackBar.snackBar && commit('hide')
            commit('setClass', 'snackModule snackAlert')
            commit('setMsg', snackMsg)
            commit('display')

            setTimeout(function () {
                commit('hide')
            }, this.state.snackBar.timeout)
        },
        snackSuccess({commit}, snackMsg) {
            this.state.snackBar.snackBar && commit('hide')
            commit('setClass', 'snackModule snackSuccess')
            commit('setMsg', snackMsg)
            commit('display')

            setTimeout(function () {
                commit('hide')
            }, this.state.snackBar.timeout)
        },
        snackWarn({commit}, snackMsg) {
            this.state.snackBar.snackBar && commit('hide')
            commit('setClass', 'snackModule snackWarning')
            commit('setMsg', snackMsg)
            commit('display')

            setTimeout(function () {
                commit('hide')
            }, this.state.snackBar.timeout)
        },
    }
    ,
    getters: {}
    ,
    mounted() {
        console.log(this.state)
    }
}